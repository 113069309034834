import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faMoneyCheck, faObjectGroup, faPlus, faRocket, faTasks, faTruck, faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { getDashboardDetails } from '../../services/userService';
import SpinDiv from "../components/SpinDiv";
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import moment from "moment";
export class DashboardOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
     users:[],
     supplier_count:0,
     branch_count:0,
     product_count:0,
     fromdate: moment().startOf('month'),
     todate: moment().endOf('day'),
     total_sales:0,
     total_purchases:0,
     total_balance:0,
     total_expenses:0,
     user:JSON.parse(localStorage.getItem('user'))

    
    };
    

  }
  componentDidMount(){
    this.getDashboardDetails();
  }
  logOut = () =>{ 
    localStorage.removeItem("user");
   localStorage.removeItem('token');
   localStorage.clear();
   let path = '/auth/sign-in'; 
   this.props.history.push(path);

 }

 getDashboardDetails = () => {


  this.setState({ loading: true });
 const {fromdate, todate}= this.state;
  getDashboardDetails({fromdate, todate}).then(
   
    (res) => {
      console.log(res)
      this.setState({
        loading: false,
        users: res.users,
        product_count: res.product_count,
        supplier_count: res.supplier_count,
        branch_count: res.branch_count,
        total_sales:res.total_sales,
        total_purchases:res.total_purchases,
        total_balance: res.total_balance,
        total_expenses:res.total_expenses
      });
    },
    (error) => {
      this.setState({ loading: false, });
    }
  );
};
formatCurrency(x){
  if(x!==null && x!==0 && x!== undefined){
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `\u20a6${parts.join(".")}`;
  }
  return '0';
}

  render() {
    const {loading, users, user,fromdate, todate, total_balance,total_expenses,supplier_count,total_purchases, product_count, total_sales, branch_count}= this.state;
    return (
      <>
        {loading && <SpinDiv text={"Loading..."} />}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
       
      <h3>{user.admin !== 1 ? `${user.branch_name}  Branch` : 'Administrator'}</h3>

       <ButtonGroup>
         <Button variant="outline-danger"  onClick={() => this.logOut()} size="sm">Sign Out</Button>
         {/* <Button variant="outline-primary" size="sm">Export</Button> */}
       </ButtonGroup>
     </div>
        
        <Row className="justify-content-md-center">
        
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Users"
              title={users.length}
              icon={faUsers}
              iconColor="shape-secondary"
            />
          </Col>
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Branches"
              title={branch_count}
              percentage={28.4}
              icon={faObjectGroup}
              iconColor="shape-tertiary"
            />
          </Col>
  
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Products"
              title={product_count}
              percentage={28.4}
              icon={faProductHunt}
              iconColor="shape-tertiary"
            />
          </Col>
          {user.admin == 1 && <>
            <Col md={4} className="mb-4">
            <CounterWidget
              category="Suppliers"
              title={supplier_count}
              percentage={28.4}
              icon={faTruck}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Total Purchase Order"
              title={this.formatCurrency(total_purchases)}
              icon={faCashRegister}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Sales"
              title={this.formatCurrency(total_sales)}
              icon={faMoneyCheck}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Credit Balance"
              title={this.formatCurrency(total_sales-total_balance)}
              icon={faMoneyCheck}
              iconColor="shape-tertiary"
            />
          </Col>

          <Col md={4} className="mb-4">
            <CounterWidget
              category="Outstanding Balance"
              title={this.formatCurrency(total_balance)}
              icon={faMoneyCheck}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col md={4} className="mb-4">
            <CounterWidget
              category="Expenses"
              title={this.formatCurrency(total_expenses)}
              icon={faMoneyCheck}
              iconColor="shape-tertiary"
            />
          </Col>

          </>}
          
        
  
         
        </Row>
  
        
      </>
    );
  
  }
}

export default DashboardOverview
